import React, {useState} from 'react';
import {ReactComponent as Point} from "../../../resources/chore-point.svg";
import {firebaseSet} from "../../../FireBase/firebase";
import {generateUuidv4} from "../../../utils/utils";

export const Reward = ({reward, project, worker}) => {
    const [expanded, setExpanded] = useState(false);

    const claim = () => {
        try {
            const points = worker.points ? parseInt(worker.points) : 0;
            const price = parseInt(reward.price);
            if (points < price) {
                alert('Hakke råd!')
                return;
            }
            firebaseSet(
                ['projects', project.id, 'workers', worker.id, 'points'],
                points - price
            )
            const id = generateUuidv4();
            firebaseSet(
                ['projects', project.id, 'requests', worker.id, id],
                {
                    id,
                    reward,
                    resolved: false
                }
            )
        } catch (e) {
            console.error('claim error: ', e);
        }

    }

    const renderExpansion = () => expanded && (
        <div className="expansion">
            <p className="mediumText">
                {reward.description}
            </p>
            <button
                className="button main marginTop20"
                onClick={claim}
            >
                Ja takk
            </button>
        </div>
    )

    return (
        <div
            key={reward.id}
            className="contrast listItem"
        >
            <div
                onClick={() => setExpanded(!expanded)}
            >
                <span className={'space'}>{reward.label}</span>
                <span className={'space'}>{reward.price}<Point/></span>
            </div>
            {renderExpansion()}
        </div>
    );
}