import React, {useState} from 'react';
import {ReactComponent as Close} from "../../../resources/close.svg";
import {ReactComponent as Point} from "../../../resources/chore-point.svg";
import {Reward} from "./Reward";
import {RewardRequests} from "./RewardRequests";

export const Worker = ({worker, project, requests}) => {
    const [expanded, setExpanded] = useState(false);

    const renderBackButton = () => (
        <>
            <button
                className="button roundButton flex center abs top10 left10"
                onClick={() => setExpanded(false)}
            >
                <Close/>
            </button>
        </>
    );

    let className = 'worker main '

    if (expanded) {
        className = className + 'expanded';
    } else {
        className = className + 'minimized rounded20';
    }

    const renderReward = (reward) => (<Reward
        key={reward.id}
        reward={reward}
        project={project}
        worker={worker}
    />)

    const renderRewards = () => {
        if (!project?.rewards) return <span className="padding20">Det finnes ikke noe å bruke poeng på ennå</span>;
        const rewards = Object.values(project.rewards);
        return (
            <div className="fullWidth">
                Bruk poeng:
                {rewards.map(renderReward)}
            </div>
        )
    }

    const renderPointRecord = () => worker.totalEarned && worker.totalEarned !== worker.points ? (
        <div className="flex center">
        <span className={'space'}>Totalt samlet:</span>
        <span className={'space'}>
                    {worker.totalEarned}
            <Point/>
                </span>
    </div>
    ) : <div/>

    const content = expanded ? (
        <>
            {renderBackButton()}
            <h3>{worker.name}</h3>
            <div className="fadeWrapper fullWidth">
                <div className="scroll fullWidth paddingTop10 paddingBottom10">
                    {renderRewards()}
                    <RewardRequests
                        requests={requests}
                    />
                </div>
            </div>
            {renderPointRecord()}
        </>
    ) : (<></>)

    return (
        <div className={className || ''}>
            {content}
            <div
                className="footer"
                onClick={() => setExpanded(true)}
            >
                <span className={expanded ? 'faded' : ''}>
                    {worker.name}
                </span>
                <span>
                {worker.points || 0}
                    <Point/>
            </span>
            </div>
        </div>
    );
}