import {useLayoutEffect, useState} from 'react';
import {ReactComponent as Logo} from './App/resources/logo.svg';
import {getLoginRedirectResult, login} from "./App/FireBase/firebase";
import {load, save, remove} from "./App/utils/utils";
import useEffectOnce from "./App/utils/UseEffectOnce";
import {Main} from "./App/Main";
import {Loader} from "./App/General/Loader/Loader";
import {IconTest} from "./IconTest";

function App() {
    const [loggingIn, setLoggingIn] = useState(false);
    const [user, setUser] = useState(load('user'));

    useLayoutEffect(() => {
        setLoggingIn(!!window.sessionStorage.getItem('pending'));
    }, [])

    useEffectOnce(() => {
        getLoginRedirectResult().then(
            usr => {
                if (!usr) return;
                save('user', usr);
                setUser(usr);
                setLoggingIn(false);
                window.sessionStorage.removeItem('pending')
            },
            error => {
                console.log('error: ', error);
            }
        )
    })

    const doLogin = () => {
        if (user)
            setUser(undefined)
        else {
            window.sessionStorage.setItem('pending', 1)
            login()
        }
    }

    const content = loggingIn ? (
        <>
            <Loader/>
            <button
                className="button main fix bottom10 right10 minitext"
                onClick={() => {

                    window.sessionStorage.removeItem('pending');
                }}
            >
                Avbryt
            </button>
        </>
    ) : !!user ? (
        <Main
            user={user}
            logout={() => {
                setUser(undefined)
                remove('user');
                remove('person');
                remove('projects');
            }}
        />
    ) : (
        <>
            <br/>
            <br/>
            <Logo/>
            <button
                onClick={doLogin}
                className={'button main large'}
            >
                Logg inn
            </button>
        </>
    )

    return (
        <div className="App">
            {/*<IconTest v={1}/>*/}
            {/*<IconTest/>*/}
            {content}
        </div>
    );
}

export default App;
