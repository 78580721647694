import React, {useState} from 'react';
import {ReactComponent as Point} from "../../../resources/chore-point.svg";
import {DeleteThing} from "../../../General/DeleteThing/DeleteThing";
import {firebaseSet} from "../../../FireBase/firebase";

export const EditWorker = ({project, worker}) => {
    const [expanded, setExpanded] = useState(false);

    const removeWorker = () => {
        if (worker.owner) return;
        firebaseSet(
            ['projects', project.id, 'workers', worker.id],
        )
        firebaseSet(
            ['projectsMembership', worker.id, project.id]
        )
    }

    const renderExpansion = () => !expanded ? <></> : (
        <>
            <br/>
            <div
                className="flex row spaceBetween"
            >
                <div>
                    Poeng tjent hittil:
                </div>
                <div>
                    <div className="points flex center">
                        {worker.totalEarned}
                        <Point/>
                    </div>
                </div>
            </div>
            <br/>
            <div
                className="flex row spaceBetween"
            >
                <div>
                    Poeng brukt:
                </div>
                <div>
                    <div className="points flex center">
                        {worker.totalEarned - worker.points}
                        <Point/>
                    </div>
                </div>
            </div>
            <br/>
            {
                !worker.owner ?(
                    <DeleteThing
                        text={`Fjerne ${worker.name}?`}
                        onConfirm={removeWorker}
                    />
                ) : <></>
            }
        </>
    );

    return (
        <div className="editWorker listItem button main">
            <div
                className="flex row spaceBetween"
                onClick={() => setExpanded(!expanded)}
            >
                <div className="flex row center">
                    {worker.name}
                </div>
                <div>
                    <div className="points flex center">
                        {worker.points}
                        <Point/>
                    </div>
                </div>
            </div>
            {renderExpansion()}
        </div>
    );
}