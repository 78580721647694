import React from 'react';
import {AdminRewardRequest} from "./AdminRewardRequest";

export const AdminRewardRequests = ({project}) => {
    if (!project.requests) return '';
    const requestGroups = Object.entries(project.requests);

    let empty = true;

    const renderRequestGroup = rg => {
        if (!rg[1]) return '';
        const worker = project.workers[rg[0]];

        const requests = Object.values(rg[1])
            .filter(r => !r.resolved);
        console.log('requests:', requests);
        if (requests.length === 0) return '';
        empty = false;
        return (
            <div
                className={'requestGroup'}
            >
                <h3>
                    {worker.name}
                </h3>
                {requests.map(request => (
                    <AdminRewardRequest
                        key={request.id}
                        project={project}
                        worker={worker}
                        request={request}
                    />
                ))}
            </div>
        )
    }



    return empty ? ('') : (
        <>
            <h2>Uhåndterte belønninger</h2>
            {requestGroups.map(renderRequestGroup)}
        </>
    );
}