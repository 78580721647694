import React, {useState} from 'react';
import {firebaseGet, firebaseSet, listen} from "./FireBase/firebase";
import {Router} from "./Router/Router";
import useEffectOnce from "./utils/UseEffectOnce";
import {load, mailToKey, remove, save} from "./utils/utils";
import {Loader} from "./General/Loader/Loader";

export const Main = ({user, logout}) => {
    const [person, setPerson] = useState(load('person'));
    const [projects, setProjects] = useState(load('projects') || null);

    useEffectOnce(() => {
        const id = user.uid;
        firebaseGet(['persons', id]).then(
            personSnap => {
                if (personSnap) {
                    setPerson(personSnap);
                } else {
                    const newPerson = {
                        id,
                        name: user.displayName.split(' ')[0],
                        gmailKey: mailToKey(user.email)
                    }
                    firebaseSet(['persons', id], newPerson);
                }
                listen(['persons', user.uid], p => {
                    if (!p) {
                        remove('person')
                        return;
                    }
                    save('person', p);
                    setPerson(p);
                })
                listen(['projectsMembership', mailToKey(user.email)], p => {
                    if (!p) {
                        setProjects(undefined)
                        remove('projects')
                        return;
                    }
                    save('projects', p);
                    setProjects(p);
                })
            })
    })

    return person && projects !== null ? (
        <Router
            user={user}
            logout={logout}
            person={person}
            projects={projects}
        />
    ) : (
        <Loader/>
    )
}