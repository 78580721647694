import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCJtNIYCSuYqkh_3ZOqqv3Iu8lWCUGS5vs",
    authDomain: "chore-point.firebaseapp.com",
    projectId: "chore-point",
    storageBucket: "chore-point.appspot.com",
    messagingSenderId: "201027742886",
    appId: "1:201027742886:web:5445150f1a8e9c8fb99867",
    measurementId: "G-HLJXMMDLLB"
};

firebase.initializeApp(firebaseConfig);


const dbRef = firebase.database().ref();

export const listen = (key, callBack) => {
    let ref = dbRef;
    if (typeof key === 'string')
        ref = ref.child(key)
    else
        for (let i = 0; i < key.length; i++) {
            ref = ref.child(key[i]);
        }
    ref.on('value', snap => callBack(snap.val()));
}

export const firebaseSet = (keys, value) => {
    let ref = dbRef;
    for (let i = 0; i < keys.length; i++) {
        ref = ref.child(keys[i]);
    }
    if (value)
        ref.set(value);
    else
        ref.remove();
}

export const firebaseGet = (keys) => {
    return new Promise(resolve => {

        let ref = dbRef;
        for (let i = 0; i < keys.length; i++) {
            ref = ref.child(keys[i]);
        }

        ref.once('value').then(snap => {
            resolve(snap.val())
        })
    })
}

export const firebaseAdd = (key, value) => {
    const push = dbRef.child(key)
        .push();
    push.set(push.key, value);
}

// LOGIN

const provider = new firebase.auth.GoogleAuthProvider();

export const login = () => firebase.auth().signInWithRedirect(provider);

export const getLoginRedirectResult = () => {
    return new Promise((resolve, reject) => {
            firebase.auth()
                .getRedirectResult()
                .then((result) => {
                        resolve(result.user);
                    },
                    (error) => {
                        reject(error);
                    })
        }
    )
}

