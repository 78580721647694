import React from 'react';
import {Link, Redirect} from "react-router-dom";


export const Home = ({
                         router,
                         logout,
                         user,
                         person,
                         projects
                     }) => {

    const projectEntries = projects && Object.entries(projects)

    const renderProject = ([id, label]) => (
        <Link
            key={id}
            className="button main"
            to={`/projects/${id}`}
        >
            {label}
        </Link>
    )

    return !projects ? (
        <div className="home flex center column fullHeight">
            <div>
                <h2>Hallais {user.displayName.split(' ')[0]}</h2>
                <p>Du er ikke med i noe prosjekt.</p>
                <p>Du kan lage et, eller bli invitert.</p>
                <div className="flex center column">
                    <Link
                        className={'space'}
                        to={'/new-project'}
                    >
                        <button className="button main">Lag prosjekt</button>
                    </Link>
                    <button
                        className="button main space"
                        onClick={logout}
                    >
                        Logg ut
                    </button>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
        </div>
    ) : projectEntries.length === 1 ? (
        <Redirect to={`/projects/${Object.keys(projects)[0]}`}/>
    ) : (
        <div className="home">
            <h2>Hallais {user.displayName.split(' ')[0]}</h2>
            <p>Velg et prosjekt</p>
            {projectEntries.map(renderProject)}
        </div>
    );
}