import React, {useState} from 'react';
import {ReactComponent as Point} from "../../../resources/chore-point.svg";
import {ThreeButtons} from "../../../General/ThreeButtons/ThreeButtons";
import {firebaseSet} from "../../../FireBase/firebase";
import {generateUuidv4} from "../../../utils/utils";

export const EditReward = ({project, reward, close}) => {
    const [label, setLabel] = useState(reward?.label || '');
    const [description, setDescription] = useState(reward?.description || '');
    const [price, setPrice] = useState(reward?.price || 1000);

    const doDelete = () => {
        if (!reward) return;
        firebaseSet(['projects', project.id, 'rewards', reward.id])
        close();
    }

    const save = () => {
        if (label.length === 0) {
            alert('Belønningen trenger et navn!')
            return;
        }
        const id = reward?.id || generateUuidv4();
        firebaseSet(
            ['projects', project.id, 'rewards', id],
            {
                id,
                label,
                description,
                price
            }
        )
        close();
    }

    return (
        <div className="editReward button main listItem textLeft mediumText">
            <div>
                <div>Navn:</div>
                <input
                    type={'text'}
                    className={'input fullWidth'}
                    onChange={e => setLabel(e.target.value)}
                    value={label}
                />
            </div>
            <br/>
            <div>
                <div>Beskrivelse:</div>
                <textarea
                    className={'input fullWidth'}
                    onChange={e => setDescription(e.target.value)}
                    value={description}
                />
            </div>
            <br/>
            <div
                className={'points flex'}
            >
                <span className={'marginRight10 paddingTop10'}>Pris:</span>
                <input
                    type={'number'}
                    className={'input fullWidth textRight'}
                    onChange={e => setPrice(e.target.value)}
                    value={price}
                />
                <Point/>
            </div>
            <br/>
            <ThreeButtons
                cancel={close}
                deleteText={'Slette belønning?'}
                doDelete={doDelete}
                save={save}
                noDelete={!reward}
            />
        </div>
    );
}