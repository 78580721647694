import React, {useState} from 'react';
import useEffectOnce from "../../utils/UseEffectOnce";
import {listen} from "../../FireBase/firebase";
import {Loader} from "../../General/Loader/Loader";
import {EditButton} from "../../General/EditButton";
import {EditProject} from "./EditProject/EditProject";
import {Task} from "./Task";
import {Link} from "react-router-dom";
import {Worker} from "./Worker/Worker";

export const Project = (props) => {
    const [project, setProject] = useState(null);

    useEffectOnce(() => {
        const projectId = props.router.match.params.id;
        listen(['projects', projectId], p => p && setProject(p));
    })

    const worker = project?.workers && project.workers[props.person.gmailKey];
    const tasks = project?.tasks && Object.values(project.tasks);
    const workers = project?.workers && Object.values(project.workers);
    let requests;
    if (project?.requests && worker)
        requests = project?.requests;
    if (requests && worker)
        requests = requests[worker.id]
    if (requests && worker)
        requests = Object.values(requests)



    const renderEditButton = () => {
        const editing = props.router.location.state?.editing;
        return worker?.admin ? (
            <>
                <Link
                    to={{state: {editing: !props.router.location.state?.editing}}}
                    className="roundButton main flex center fix top10 left10 z100"
                >
                    <EditButton
                        editing={editing}
                        className={'contrast'}
                    />
                </Link>
                <EditProject
                    project={project}
                    tasks={tasks}
                    workers={workers}
                />
            </>
        ) : <></>
    }

    const renderTask = t => (<Task
        key={t.id}
        task={t}
        project={project}
        worker={worker}
    />)

    return !project ? (
        <Loader/>
    ) : props.router.location.state?.worker ? (
        <Worker

        />
    ) : (
        <div className={`project${worker?.admin && props.router.location.state?.editing ? ' moveRight' : ''}`}>
            <br/>
            {project.name}
            {renderEditButton()}

            <div
                className="scroll"
                style={{
                    position: 'absolute',
                    top: '4rem',
                    left: 0,
                    right: 0,
                    bottom: '4rem',
                }}
            >
                <div>
                    <div className="marginTop4rem">
                        {tasks && tasks.map(renderTask)}
                    < /div>
                < /div>
            < /div>
            {worker && (
                <Worker
                    worker={worker}
                    project={project}
                    requests={requests}
                />
            )}
        </div>
    );
}