import React, {useState} from 'react';
import {RewardRequest} from "../../Worker/RewardRequest";
import {firebaseSet} from "../../../../FireBase/firebase";

export const AdminRewardRequest = ({project, worker, request}) => {
    const [resolving, setResolving] = useState(false);

    const resolve = () => {
        firebaseSet(
            ['projects', project.id, 'requests', worker.id, request.id, 'resolved'],
            true
        )
    }

    const extra = resolving ? (
        <>
            Arbeideren har fått som fortjent?
            <div className="flex center row">
                <button
                    className="button main space"
                    onClick={(e) => {
                        resolve();
                        e.stopPropagation();
                    }}
                >Jepp</button>
                <button
                    className="button main space"
                    onClick={(e) => {
                        setResolving(false);
                        e.stopPropagation();
                    }}
                >Nej</button>
            </div>
        </>
    ) : (
        <button
            className="button main"
            onClick={(e) => {
                setResolving(true);
                e.stopPropagation();
            }}
        >
            Levert
        </button>
    )

    return (
        <RewardRequest
            request={request}
            extraContent={extra}
        />
    );
}