import React, {useState} from 'react';
import useEffectOnce from "../../utils/UseEffectOnce";
import {getDifferenceString} from "../../utils/utils";
import moment from "moment";

let timer;
export const CountDown = ({time}) => {
    const [string, setString] = useState('');

    const doit = () => setString(getDifferenceString(moment() , time))

    useEffectOnce(() => {
        timer = setInterval(() => {
            doit();
        }, 1000)
        return () => clearInterval(timer);
    })

    return <div className="marginTop20">{string}</div>
}