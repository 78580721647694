import React, {useState} from 'react';
import {firebaseSet} from "../../FireBase/firebase";
import {generateUuidv4, mailToKey} from "../../utils/utils";
import {Link} from "react-router-dom";

const addTask = (tasks, task) => {
    const id = generateUuidv4();
    task.id = id;
    tasks[id] = {id, ...task};
}

export const NewTeam = ({user, router}) => {
    const [name, setName] = useState('');
    const create = () => {
        if (name.length === 0) return;
        const id = generateUuidv4();
        const tasks = {}
        addTask(tasks, {
            label: 'Støvsuge taket',
            points: 1000,
            cooldown: 7,
        })
        addTask(tasks, {
            label: 'Male katten',
            points: 500,
            cooldown: 1,
        })
        firebaseSet(['projects', id], {
            id,
            name,
            workers: {
                [mailToKey(user.email)] : {
                    id: mailToKey(user.email),
                    name: user.displayName.split(' ')[0],
                    owner: true,
                    admin: true,
                    points: 0,
                }
            },
            tasks
        })
        firebaseSet(['projectsMembership', mailToKey(user.email)], {[id]: name});
        router.history.replace(`/projects/${id}`);
    }

    return (
        <div
            className="newTeam flex center column fullHeight padding10"
        >
            <div>
                <h1>Nytt prosjekt</h1>
                <label className="flex column start">
                    Navn
                    <input
                        className="fullWidth"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </label>
                <br/>
                <br/>
                <div className="flex center">
                    <Link
                        className={'space'}
                        to={'/'}
                    >
                        <button className="button main">
                            Tilbake
                        </button>
                    </Link>
                    <button
                        className="button main space"
                        onClick={create}
                    >
                        Opprett
                    </button>
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    );
}