import React, {useState} from 'react';
import {ReactComponent as Save} from "../../../resources/save.svg";
import {firebaseSet} from "../../../FireBase/firebase";
import {mailToKey} from "../../../utils/utils";
import {ThreeButtons} from "../../../General/ThreeButtons/ThreeButtons";

export const AddWorker = ({project}) => {
    const [adding, setAdding] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const clear = () => {
        setAdding(false);
        setEmail('');
        setName('');
    }

    const save = () => {
        const emailKey = mailToKey(email);
        firebaseSet(
            ['projects', project.id, 'workers', emailKey],
            {
                id: emailKey,
                name,
                points: 0,
                totalEarned: 0
            }
        )
        firebaseSet(
            ['projectsMembership', emailKey, project.id],
            project.name
        )
        clear();
    }

    const renderInput = (val, setVal, className) => (
        <input
            className={`input secretInput long ${className}`}
            type={'text'}
            value={val}
            onChange={e => setVal(e.target.value)}
        />
    )

    return adding ? (
        <div className="addWorker listItem button main editing">
            <h3>
                Legg til arbeider
            </h3>
            <p className="minitext">
                Må være samme epost som på google kontoen
            </p>
            <div className="marginTop10">
                Epost: {renderInput(email, setEmail, 'mail')}
            </div>
            <div className="marginTop10">
                Navn: {renderInput(name, setName, 'name')}
            </div>
            <ThreeButtons
                cancel={clear}
                save={save}
                noDelete
            />
        </div>
    ) : (
        <button
            className="button addButton main marginAuto"
            onClick={() => setAdding(true)}
        />
    );
}