import React, {useState} from 'react';
import {ReactComponent as Delete} from "../../resources/delete.svg";

export const DeleteThing = ({
                                 text,
                                 onConfirm,
                                 confirmLabel,
                                 cancelLabel

                             }) => {
    const [deleting, setDeleting] = useState(false);
    return (
        <div>
            {
                deleting ? (
                    <>
                        {text}
                        <div className="flex center row">
                            <button
                                className="button contrast space danger"
                                onClick={onConfirm}
                            >
                                {confirmLabel || 'Ja'}
                            </button>
                            <button
                                className="button contrast space safe"
                                onClick={() => setDeleting(false)}
                            >
                                {cancelLabel || 'Nei'}
                            </button>
                        </div>
                    </>
                ) : (
                    <button
                        className={'button roundButton contrast flex center marginAuto'}
                        onClick={() => setDeleting(true)}
                    >
                        <Delete/>
                    </button>
                )
            }
        </div>
    );
}