import React, {useState} from 'react';
import {EditTask} from "./EditTask";
import {generateUuidv4} from "../../../utils/utils";
import {firebaseSet} from "../../../FireBase/firebase";
import {EditWorker} from "./EditWorker";
import {AddWorker} from "./AddWorker";
import {EditReward} from "./EditReward";
import {ReactComponent as Point} from "../../../resources/chore-point.svg";
import {AdminRewardRequests} from "./AdminRewardRequests/AdminRewardRequests";

export const EditProject = ({project, workers, tasks}) => {
    const [editingReward, setEditingReward] = useState(null);

    const renderRequests = () => (
        <AdminRewardRequests
            project={project}
        />
    )

    const renderWorkers = () => (
        <>
            <div className="workerList">
                <h2>Arbeidere</h2>
                {workers && workers.map(renderWorker)}
                <AddWorker project={project}/>
            </div>
            <br/>
        </>
    )

    const renderWorker = w => {
        return (
            <EditWorker key={w.id} worker={w} project={project}/>
        )
    }

    const renderTasks = () => (
        <>
            <h2>Oppgaver</h2>
            {tasks && tasks.map(renderTask)}
            <button
                className="button addButton main marginAuto"
                onClick={() => {
                    const id = generateUuidv4();
                    firebaseSet(['projects', project.id, 'tasks', id],
                        {
                            id,
                            label: 'Ny oppgave',
                            points: 0,
                            cooldown: 7,
                        });
                }}
            />
            <br/>
        </>
    )

    const renderTask = t => {
        return (
            <EditTask key={t.id} task={t} project={project}/>
        )
    }

    const renderRewards = () => {
        const rewards = project.rewards ? Object.values(project.rewards) : [];
        return (
            <>
                <h2>Belønning</h2>
                {rewards.map(renderReward)}
                {editingReward === 'new' && (
                    <EditReward
                        project={project}
                        close={() => setEditingReward(null)}
                    />
                )}
                <button
                    className="button addButton main marginAuto"
                    onClick={() => setEditingReward('new')}
                />
                <br/>
            </>
        )
    }

    const renderReward = (reward) => editingReward === reward.id ? (
        <EditReward
            key={reward.id}
            reward={reward}
            project={project}
            close={() => setEditingReward(null)}
        />
        ) : (
        <div
            key={reward.id}
            className="reward listItem main flex spaceBetween"
            onClick={() => setEditingReward(reward.id)}
        >
            <span className="label">{reward.label}</span>
            <span className="points">
                <span className="flex center">
                    {reward.price}
                    <Point/>
                </span>
            </span>
        </div>

    )

    return (
        <div className="editProject">
            <span className={'adminLabel'}>Administrer</span>
            {project.name}
            <br/>
            <div
                className="scroll"
                style={{
                    position: 'absolute',
                    top: '4rem',
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                <div>
                    {renderRequests()}
                    {renderWorkers()}
                    {renderTasks()}
                    {renderRewards()}
                </div>
            </div>
        </div>
    );
}