import React from 'react';
import {RewardRequest} from "./RewardRequest";

export const RewardRequests = ({requests}) => {
    if (!requests) return '';
    const pending = requests.filter(r => !r.resolved)
    const renderPending = r => {
        return (
            <RewardRequest
                key={r.id}
                request={r}
            />
        );
    }

    return (
        <div className="fullWidth">
            Du ba om:
            {pending.map(renderPending)}
        </div>
    );
}