import React, {useState} from 'react';
import {ReactComponent as Point} from "../../../resources/chore-point.svg";
import {firebaseSet} from "../../../FireBase/firebase";
import {ThreeButtons} from "../../../General/ThreeButtons/ThreeButtons";

export const EditTask = ({project, task}) => {
    const [editing, setEditing] = useState(false);
    const [label, setLabel] = useState(task.label);
    const [points, setPoints] = useState(task.points);
    const [cooldown, setCooldown] = useState(task.cooldown);

    const save = () => {
        firebaseSet(['projects', project.id, 'tasks', task.id],
            {...task, label, points, cooldown});
        setEditing(false);
    }

    const cancel = () => {
        setEditing(false);
        setLabel(task.label);
        setPoints(task.points);
        setCooldown(task.cooldown);
    }

    const renderInput = (type, val, setVal, className) => (
        <input
            className={`input secretInput ${className}`}
            type={type}
            value={val}
            onChange={e => setVal(e.target.value)}
        />
    )

    const expansionContent = editing ? (
        <>
            <br/>
            <label className="flex row start">
                <span className="text1">
                    Hver
                </span>
                {renderInput(
                    'number',
                    cooldown,
                    setCooldown,
                    'textCenter smallMargin'
                )}
                <span className="text1">
                    dag
                </span>
            </label>
            <br/>
            <ThreeButtons
                cancel={cancel}
                deleteText={'Slette oppgave?'}
                doDelete={() => firebaseSet(['projects', project.id, 'tasks', task.id])}
                save={save}
            />
        </>
    ) : <></>

    return (
        <div
            className={`task listItem editableTask main ${editing ? ' editing' : ''}`}
            onClick={() => editing || setEditing(true)}
        >
            <div className="taskHeader flex spaceBetween">
                <div className="label">
                    <textarea
                        className={'input secretInput'}
                        onChange={e => setLabel(e.target.value)}
                        value={label}
                    />
                </div>
                <div className="points">
                    <div className="flex center">
                        {renderInput(
                            'number',
                            points,
                            setPoints,
                            'textRight'
                        )}
                        <Point/>
                    </div>
                </div>

            </div>
            <div
                className="expansionContainer"
            >
                {expansionContent}
            </div>
        </div>
    );
}