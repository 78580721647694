import React from 'react';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {Menu} from "../Menu/Menu";
import {FourOhFour} from "../Pages/FourOhFour";
import {Home} from "../Pages/StartPage/Home";
import {NewTeam} from "../Pages/NewTeam/NewTeam";
import {Project} from "../Pages/Project/Project";
export const Router = (props) => {
    const renderLink = (label, url, onClick, className) => (
        <Link
            key={label}
            className={className || ''}
            to={url}
            onClick={onClick}
        >{label}</Link>
    )

    const renderMenu = () => (
        <Menu>
            {/*{renderLink('Hjem', '/')}*/}
            {/*{counts.map(count => renderLink(count.name, `/counts/${count.id}`))}*/}
            {renderLink(
                'Logg ut',
                '/',
                props.logout,
                "button contrast"
            )}
            <br/>
        </Menu>
    )

    return (
        <BrowserRouter>
            {renderMenu()}
            <div className="appContent">
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(router) => (
                            <Home router={router} {...props}/>
                        )}
                    />
                    <Route
                        exact
                        path="/new-project"
                        render={(router) => (
                            <NewTeam router={router} {...props}/>
                        )}
                    />
                    <Route
                        path="/projects/:id"
                        render={(router) => (
                            <Project router={router} {...props}/>
                        )}
                    />
                    <Route
                        path="/"
                        render={() => (<FourOhFour/>)}
                    />
                </Switch>
            </div>
        </BrowserRouter>
    );
}