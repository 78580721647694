import React from 'react';
import {Link} from "react-router-dom";

export const FourOhFour = () => (
    <div className="page flexcenter">
        <Link
            className="button backButton"
            to={'/'}
        >
            {'<'}
        </Link>
        <h1 className={'header textCenter'}>404</h1>
        <h2 className={'header textCenter'}>Her var det tomt git!</h2>
    </div>
);