import React from 'react';
import {ReactComponent as Back} from "../../resources/back.svg";
import {DeleteThing} from "../DeleteThing/DeleteThing";
import {ReactComponent as Save} from "../../resources/save.svg";

export const ThreeButtons = ({
                                 cancel,
                                 deleteText,
                                 doDelete,
                                 save,
                                 noDelete
                             }) => {
    return (
        <div className="flex center row">
            <button
                className="button contrast roundButton flex center space"
                onClick={cancel}
            >
                <Back/>
            </button>
            {noDelete || (
                <DeleteThing
                    text={deleteText}
                    onConfirm={doDelete}
                />
            )}
            <button
                className="button contrast roundButton flex center space"
                onClick={save}
            >
                <Save/>
            </button>
        </div>
    );
}