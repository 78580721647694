import React from 'react';

export const EditButton = ({editing, className}) => {

    const paths = [[
        {d: "m 75.51,32.445 13.816,-13.816 -71.799,3.67e-4 0.08559,94.693003 93.328,8e-4 -14.149,-14.237003 -65.879,-0.001 -0.08334,-66.667 z", opacity: 1},
        {d: "M 110.85,113.32 96.703,99.099 96.669623,53.714 110.82462,39.583 Z", opacity: 1},
        {d: "M 67.56,74.673 49.888,79.3373 54.59136,61.7161 Z", opacity: 1},
        {d: "m 58.800753,58.613679 12.81,12.81 5.516838,-5.613168 C 90.634525,52.106925 96.71548,46.049656 107.05252,35.766875 l 2.16999,-2.162112 9.98011,-9.869975 -4.5864,-4.654104 c -0.42747,-0.404499 -7.95225,-7.848446 -8.24639,-8.131313 z", opacity: 1},
    ],
    [
        {d: "m 75.51,32.445 13.816,-13.816 -71.799,3.67e-4 0.08559,94.693003 93.328,8e-4 -14.149,-14.237003 -65.879,-0.001 -0.08334,-66.667 z", opacity: 0},
        {d: "M 110.85,113.32 96.703,99.099 96.669623,53.714 110.82462,39.583 Z", opacity: 0},
        {d: "M 47.523592,90.234826 3.0150594,50.308197 47.50654,10.22314 Z", opacity: 1},
        {d: "m 46.204627,31.260652 0.07908,37.414394 35.724818,0.02568 c 10.107714,0.114828 9.434525,13.573056 0.190481,13.477155 l -22.227673,0.0273 0.03964,37.369679 22.208236,-0.002 C 143.45111,119.53378 144.40186,30.99959 81.95138,31.283461 Z", opacity: 1},
    ]];

    const renderPaths = () => {
        return paths[editing ? 1 : 0].map(renderPath)
    }

    const renderPath = ({d, opacity}, i) => {
        return (
            <path
                key={i}
                style={{transition: `d 300ms, fill-opacity 300ms`, fillOpacity: opacity}}
                d={d}
            />
        )
    }

    return (
        <svg
            className={`editButton ${className || ''}`}
            viewBox="0 0 132.29 132.29"
            height="40" width="40"
        >
            {renderPaths()}
        </svg>
    );
}