import React, {useState} from 'react';

export const RewardRequest = ({request, extraContent}) => {
    const [expanded, setExpanded] = useState(false);



    const renderExpansion = () => expanded && (
        <div className="expansion">
            <p className="mediumText">
                {request.reward.description}
            </p>
            {extraContent}
        </div>
    )

    return (
        <div
            className="listItem contrast"
            key={request.id}
            onClick={() => setExpanded(!expanded)}
        >
            {request.reward.label}
            {renderExpansion()}
        </div>
    );
}