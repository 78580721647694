import React, {useState} from 'react';
import {ReactComponent as Point} from "../../resources/chore-point.svg";
import {firebaseSet} from "../../FireBase/firebase";
import moment from 'moment';
import {formatDate, parseDate} from "../../utils/utils";
import {CountDown} from "./CountDown";

export const Task = ({task, project, worker}) => {
    const [selected, setSelected] = useState(null);

    const claim = () => {
        try {
            const oldPoints = worker.points ? parseInt(worker.points) : 0;
            const oldTotal = worker.totalEarned ? parseInt(worker.totalEarned) : 0;
            const taskPoints = worker.totalEarned ? parseInt(task.points) : 0;
            firebaseSet(
                ['projects', project.id, 'workers', worker.id, 'points'],
                oldPoints + taskPoints
            )
            firebaseSet(
                ['projects', project.id, 'workers', worker.id, 'totalEarned'],
                oldTotal + taskPoints
            )
            firebaseSet(
                ['projects', project.id, 'tasks', task.id, 'claimed'],
                formatDate(moment())
            )
        } catch (e) {
            console.error('claim error: ', e);
        }
    }

    let cooldownRemaining = -1;

    if (task.claimed) {
        const parsed = parseDate(task.claimed);
        cooldownRemaining = parsed - moment().subtract(task.cooldown, 'day');
    }

    const onCooldown = cooldownRemaining > 0;
    const toggle = () => setSelected(!selected)

    return (
        <div
            className={`task listItem button main${onCooldown ? ' faded' : ''}`}
            onClick={() => onCooldown && toggle()}

        >
            <div
                className="taskHeader flex spaceBetween"
                onClick={() => onCooldown || toggle()}
            >
                <span className="label">{task.label}</span>
                <span className="points">
                <span className="flex center">
                    {task.points}
                    <Point/>
                </span>
            </span>
            </div>
            <div
                style={{
                    transition: 'height 200ms',
                    height: selected ? 80 : 0,
                    overflow: 'hidden',
                }}
            >
                {
                    cooldownRemaining > 0 ? (
                        <CountDown
                            time={parseDate(task.claimed).add(task.cooldown, 'day')}
                        />
                    ) : (
                    <button
                        className="button contrast marginTop20"
                        onClick={claim}
                    >
                    Ferdig!
                    </button>
                    )
                }
            </div>
        </div>
    );
}